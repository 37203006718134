@import "../../variables";


.MuiExpansionPanelSummary-root.MuiExpansionPanelSummary-root {
  padding: 0;

  .MuiExpansionPanelSummary-content {
    display: flex;
    align-items: center;

    .remove {
      display: none;
    }

    .MuiIcon-root {
      color: #790072;
    }

    &.Mui-expanded {
      .add {
        display: none;
      }

      .remove {
        display: block;
      }
    }
  }
}

.MuiCollapse-wrapperInner {
  .MuiExpansionPanelDetails-root {
    padding: 0 0 16px;
    display: block;
    font-size: 16px;
    width: 92%;

    & p {
      font-family: $fontDefault;
    }
  }
}

.newFlag {
  font-size: 12px;
  color: #bb291e;
  margin-left: 5px;
  position: relative;
  display: inline-block;
  top: -12px;
  font-family: $fontDefault;
}

.MuiTypography-h6 {
  font-size: 20px !important;
  font-family: $fontDefault !important;
  font-weight: normal !important;
  line-height: 24px !important;

  @include md {
    margin-right: auto !important;
  }

}