@import '../../variables';

.DocumentsAndFormulars {
  font-family: $fontDefault;

  .Box {
    height: 100%;
  }
  .InnerLayout {
    margin-top: -100px;
    z-index: 1;
    position: relative;
  }

  .titel {
    font-family: $fontDefault;
    font-size: 36px;
    color: #c30078;
    margin-bottom: 14px;
  }
}
