@import "../../variables";

.text-class {
    font-family: $fontDefault;
}

.content {
    font-size: 18px;
    line-height: 28px;
}

.pay-now {
    width: 190px;
    background: #009696 0% 0% no-repeat padding-box;
    border-radius: 25px;
    color: white;
    height: 50px;

    @include ms {
        width: 90px;
    }
}


.text-field .MuiFormLabel-root,
.text-field h2,
.text-field-input,
.Mui-focused {
    color: #009696 !important;
}

.text-field-input::after {
    border-bottom: 2px solid #009696 !important;
}

.MuiInput-underline-24:hover:not(.MuiInput-disabled-23):not(.MuiInput-focused-22):not(.MuiInput-error-25):before {
    border-bottom: 2px solid #009696 !important;
}

.MuiFormControl-marginDense {
    width: 100%;
}