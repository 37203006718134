$breakpoint-xs: "0px";
$breakpoint-sm: "600px";
$breakpoint-ms: "960px";
$breakpoint-md: "1140px";
$breakpoint-lg: "1280px";
$breakpoint-xl: "1920px";

@mixin xs {
  @media (max-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin ms {
  @media (max-width: #{$breakpoint-ms}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$breakpoint-lg}) {
    @content;
  }
}

@mixin xl {
  @media (max-width: #{$breakpoint-xl}) {
    @content;
  }
}

$primaryColor: #93c01f;
$secondaryColor: #004685;
$textColor: #2b2e34;
$lightTextColor: #737373;
$highlightColor: #009fd6;

$starColor: #fcc200;

$red: #d10a11;
$backgroundGradient: linear-gradient(200deg,
  $secondaryColor 0%,
  $secondaryColor 40%,
  $primaryColor 100%);
$fontDefault: Open Sans,
sans-serif;
$fontSpecial: Hiruko Pro,
sans-serif;

$fontSizeDefault: 1rem;
$fontSizeSmall: 0.857rem;
$fontSizeExtraSmall: 0.714rem;

$fontSizeHeadline1: 2.7rem;
$fontSizeHeadline2: 2rem;
$fontSizeHeadline3: 1.7rem;
$fontSizeHeadline4: 1rem;

$boxPadding: 32px 24px;