@import "../../variables";

.Box {

  .how-work-cpp-titel {
    text-align: center;
    justify-content: center;
    color: #790072;

  }

  .how-work-cpp-subtitel {
    margin-top: 0;
    font-size: 20px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 660px;

    @media only screen and (max-width: 650px) {
      width: unset;
    }

  }

  .how-work-cpp-success {
    margin-top: 0;
    font-size: 16px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 840px;

    @include ms {
      width: unset;
    }
  }
}

.how-work-cpp-container {
  font-family: $fontDefault;
  line-height: 26px;
  font-size: 16px;
  margin-bottom: 50px;
}

div.textWithOpacity {
  p:last-of-type {
    opacity: 0.65;
    font-size: 14px;
  }
}

.textWithColor-HWC {
  color: #790072;
  font-family: Helvetica;
  font-size: 20px;
}

.grid-item {
  @include md {
    margin: 0 !important;
  }
}

.register-data,
.be-sure {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px 30px 0;
  background: #fff;
  position: relative;
  height: 100%;
}

.register-data {
  justify-content: space-around;
}