@import "../../variables";


.cpp-titel-img {
    padding-top: 5em;
    padding-bottom: 2em;
    width: 100%;
    max-height: 620px;
    background-position: top center;
    display: flex;
    justify-content: center;

    & img {
        object-fit: cover;
    }

    @include ms {
        padding-top: 2em;
    }

    @media only screen and (max-width: 1500px) {
        & img {
            width: 100%;
        }
    }
}

.Box {

    .titel {
        font-family: $fontDefault;
        font-size: 36px;
        color: #C30078;
        margin-bottom: 14px;
    }

    .subtitel {
        margin-top: 0;
        font-size: 20px;
        color: #333333;
        margin-bottom: 10px;
        line-height: 32px;

    }

    .text-class {
        font-family: $fontDefault;
    }

    .content {
        font-size: 16px;
        line-height: 28px;
    }

    div.twoColors {
        p:last-of-type {
            color: #790072;
            font-weight: 600;
        }
    }
}