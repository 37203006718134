@import "../../variables";

.Box {
  background: none;

  .advantages-subtitel {
    margin-top: 0;
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 10px;

  }

  .advantages-text-class {
    font-family: $fontDefault;
    line-height: 32px;
  }

  .content {
    font-size: 16px;
  }

  ol {
    list-style: none;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    column-gap: 70px;

    @include md {
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
    }

    li {
      margin-bottom: 10px;
      color: white;
      font-family: $fontDefault;
      font-size: 16px;
      position: relative;
      line-height: 28px;

    }

    li::before {
      position: absolute;
      content: "";
      background-image: url(/assets/images/icons/shieldCheck.svg);
      background-size: contain;
      display: inline-block;
      padding: 0;
      width: 25px;
      margin-left: -40px;
      height: 25px;
      margin-top: 2px;
    }
  }

  .infos {
    color: white;
    font-size: 14px;
    font-family: $fontDefault;
    line-height: 26px;

    a {
      text-decoration: underline;
      text-decoration-color: white;
      color: white;
    }
  }

}