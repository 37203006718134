@import "../../variables";

.navigation {
  width: 100%;
  position: fixed;
  display: flex;
  color: #009696;
  justify-content: space-between;
  align-items: center;
  z-index: 20;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;

  .nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.25rem;
    padding: 0 18px;

    .nav-item-list {
      flex-grow: 1;
      padding: 0 0 0 50px;

      @include ms {
        margin-bottom: 4px;
      }

      nav {
        display: flex;
        justify-content: center;
        align-items: center;

        .card-protection {
          width: 130px;
          color: #009696;
        }

        .documents {
          width: auto;
          padding: 1vw;
          color: #009696;
        }

        .faq {
          width: 90px;
          color: #009696;
        }

        .owl {
          width: 90px;
          
          & a {
            text-decoration: unset;
            color: #009696;
          }
        }

        .login {
          width: 190px;
          background: #009696 0% 0% no-repeat padding-box;
          border-radius: 25px;
          color: white;

          @include ms {
            width: 90px;
          }
        }

        .card-protection,
        .documents,
        .faq,
        .login,
        .owl {
          font-size: 16px;
          font-family: $fontDefault;
          font-weight: 600;
          height: 40px;
          padding-top: 10px;
          cursor: pointer;
          margin: 0 4px;
          float: left;
          display: flex;
          justify-content: center;
          -webkit-touch-callout: none;
          /* iOS Safari */
          -webkit-user-select: none;
          /* Safari */
          -khtml-user-select: none;
          /* Konqueror HTML */
          -moz-user-select: none;
          /* Old versions of Firefox */
          -ms-user-select: none;
          /* Internet Explorer/Edge */
          user-select: none;
          /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
            &:hover{
              background-color: #009696aa;
              color: #ffffff;
              border-radius: 5px;
              a{
                color:#ffffff;
              }
            }
        }
      }
    }
  }

  .logo {
    max-width: 300px;

    @include lg {
      padding: 20px 0;
    }

    a {
      color: $textColor;
      line-height: 1.1;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.1px;

      span {
        display: none;

        @include md {
          display: inline;
        }
      }

      img {
        width: 100%;
      }
    }
  }

  @include xl {
    padding: 0;

    .nav-wrapper {
      padding: 10px 0;

      .nav-item-list {
        padding: 0;
      }
    }
  }

  @media (max-width: #{$breakpoint-lg}) {
    & > .logo {
      align-self: flex-start;
      flex: 1;
    }
  }
}

.burger {
  display: flex;

  @include lg {
    display: none;
  }

  .MuiButton-root:hover {
    text-decoration: none;
    background-color: unset;
  }
}

.swal2-styled.swal2-confirm {
  background-color: #009696 !important;
}

.swal2-popup {
  width: 34em !important;
}

.swal2-content {
  text-align: unset !important;
  font-family: $fontDefault;
  line-height: $fontSizeHeadline3 !important;
}

.swal2-styled.swal2-confirm {
  background: #009696 0% 0% no-repeat padding-box !important;
  border-radius: 25px !important;
  color: white !important;
}
