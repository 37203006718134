@import "./variables";


html {
  height: 100%;
}

body,
#root {
  min-height: 100vh;
}

html,
body {
  font-family: $fontDefault;
  font-weight: 300;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: #F7F7F7 0% 0% no-repeat padding-box;
}

* {
  box-sizing: border-box;
}

.App {
  position: relative;
}

.MuiContainer-maxWidthLg {
  max-width: 1320px !important;
}

.MuiGrid-spacing-xs-4>.MuiGrid-item {
  padding: 12px !important;
}

.home,
.documents,
.faq,
.cpID,
.owl {
  color: #009696;
  text-decoration: unset;

  & a {
    text-decoration: unset;
    color: #009696;

  }
}

.swal2-shown {

  padding-right: 0 !important;
}

.layout {
  position: relative;
}