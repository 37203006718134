@import "../../variables";

.InnerLayout {
  padding-top: 0em;
  padding-bottom: 4em;
  overflow-y: hidden;
}

.card-protection-advantages-class,
.footer-class {
  background: transparent linear-gradient(108deg, #C30078 0%, #790072 100%) 0% 0% no-repeat padding-box;
}

.footer-class {
  height: 100px;
  padding-top: 2em;
}

.faq-layout,
.imprint,
.dataProtection,
.agb {
  padding-top: 12em;
  padding-bottom: 14em;

  @include ms {
    padding-top: 6em;
    padding-bottom: 10em;
  }
}

.payment-layout {
  padding-top: 6em;
  padding-bottom: 6em;
}