@import "../../variables";

.contact-box {
    @include sm {
        padding: 0 !important;
    }
}

.faq-telephone-box {
    @include sm {
        padding: 0 !important;
    }
}

.faq-text-class {
    font-family: $fontDefault;
    line-height: $fontSizeHeadline3;
    font-size: 16px;
}

.content {
    font-size: 16px;
}

div.textWithColor {
    p:last-of-type {
        color: #790072;
        font-weight: 600;
    }
}

.day,
.time,
.faq-telephone {
    line-height: unset !important;
    margin: 0;
    max-width: 320px;

}

@include ms {
    .faq-qq {
        padding: 30px 0 0 0 !important;
    }
}